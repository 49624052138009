<template>
  <v-card elevation="1" class="mb-4">
    <v-card-text>
      <h6 class="text-h6 black--text">Datos generales</h6>
      <p class="text-body-2 mb-0">Ingrese el emisor de la factura, el receptor y el tipo de comprobante</p>

      <v-row class="mt-0">
        <v-col md="4" class="mt-2">
          <v-combobox
            v-model="invoice.emitter"
            data-vv-as="emisor"
            name="emitter"
            item-text="name"
            item-value="id"
            :items="emitters"
            label="Emisor*"
            autocomplete="off"
            v-validate="'required|combobox_required'"
            :loading="loading_combos['emitters']"
            v-on:change="
              emitterChangeCombo($event);
              getCbtesTipos();
            "
            :disabled="invoice_to_cancel != null || emitter != null || invoice.id > 0"
          ></v-combobox>
          <span class="red--text">{{ errors.first("emitter") }}</span>
        </v-col>

        <v-col md="4" class="mt-2">
          <v-combobox
            v-model="invoice.receiver"
            data-vv-as="receptor"
            name="receiver"
            :item-text="receiver_name"
            item-value="id"
            :items="receivers"
            label="Receptor*"
            autocomplete="off"
            v-validate="'required|combobox_required'"
            :loading="loading_combos['receivers']"
            v-on:change="receiverChangeCombo($event)"
            :disabled="invoice_to_cancel != null || receiver != null || invoice.id > 0"
          ></v-combobox>
          <span class="red--text">{{ errors.first("receiver") }}</span>
        </v-col>
        <v-col md="4">
          <v-combobox
            v-model="invoice.cbte_tipo"
            data-vv-as="tipo de comprobante"
            name="cbte_tipo"
            item-text="Desc"
            item-value="Id"
            :items="cbte_tipos"
            label="Tipo de comprobante*"
            autocomplete="off"
            v-validate="'required|combobox_required'"
            class="overflow-auto"
            :loading="loading_combos['cbte_tipos_invoice']"
            :disabled="cbte_tipos.length == 0 || invoice_to_cancel != null || (invoice.id > 0 && !sale_order)"
            filled
          ></v-combobox>
          <span class="red--text">{{ errors.first("cbte_tipo") }}</span>
        </v-col>
      </v-row>

      <v-row class="mt-0">
        <v-col md="3">
          <v-combobox
            :loading="loading_combos['ptosvtas']"
            :disabled="ptosvtas.length == 0 || invoice_to_cancel != null || (invoice.id > 0 && !sale_order)"
            v-model="invoice.pto_vta"
            data-vv-as="punto de venta"
            name="pto_vta"
            :items="ptosvtas"
            label="Punto de venta*"
            autocomplete="off"
            v-validate="'required'"
          ></v-combobox>
          <span class="red--text">{{ errors.first("pto_vta") }}</span>
        </v-col>

        <v-col md="3">
          <v-combobox
            :loading="loading_combos['conceptos']"
            :disabled="conceptos.length == 0 || invoice_to_cancel != null || (invoice.id > 0 && !sale_order)"
            v-model="invoice.concepto"
            data-vv-as="concepto"
            name="concepto"
            item-text="Desc"
            item-value="Id"
            :items="conceptos"
            label="Concepto*"
            autocomplete="off"
            v-validate="'required|combobox_required'"
          ></v-combobox>
          <span class="red--text">{{ errors.first("concepto") }}</span>
        </v-col>

        <v-col md="3">
          <!--Por ahora solo se puede emitir en ARS-->
          <!--monedas.length == 0 || invoice_to_cancel != null || (invoice.id > 0 && !sale_order)-->
          <v-combobox
            :loading="loading_combos['monedas']"
            :disabled="true"
            v-model="invoice.moneda"
            data-vv-as="moneda"
            name="moneda"
            item-text="Desc"
            item-value="Id"
            :items="monedas"
            label="Moneda*"
            autocomplete="off"
            v-validate="'required|combobox_required'"
          ></v-combobox>
          <span class="red--text">{{ errors.first("moneda") }}</span>
        </v-col>

        <v-col md="3">
          <v-text-field
            v-if="invoice.moneda != null && invoice.moneda.Id !== 'PES'"
            autocomplete="off"
            data-vv-as="cotizacion de la moneda"
            label="Cotización de la moneda*"
            name="mon_cotiz"
            v-validate="'decimal|required'"
            v-model="invoice.mon_cotiz"
            :disabled="invoice_to_cancel != null || (invoice.id > 0 && !sale_order)"
          >
          </v-text-field>
          <span class="red--text">{{ errors.first("mon_cotiz") }}</span>
        </v-col>
      </v-row>

      <v-row class="mt-0">
        <v-col md="3" v-if="showOrdenCompra()">
            <v-text-field              
              autocomplete="off"
              data-vv-as="Nº Orden de Compra"
              label="Nº Orden de Compra"
              name="nro_orden_compra"
              placeholder="Nº Orden de Compra"
              :v-validate="requiredReceivers()"              
              v-model="invoice.nro_orden_compra"
              class="mt-3"
              :disabled="isViewModal"
            />
            <span class="red--text">{{ errors.first("nro_orden_compra") }}</span>
          </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
import { emmiterService } from "@/libs/ws/emmiterService";
import { receiversService } from "@/libs/ws/receiversService";
import { vouchersService } from "@/libs/ws/vouchersService";
import { billingGeneralService } from "@/libs/ws/billingGeneralService";
import ConstantSettings from "@/constants/settings";
export default {
  created() {
    this.$validator = this.validator;
  },
  components: {},
  props: {
    open: Boolean,
    invoice: Object,
    loading_combos: Object,
    getLastvoucherOf: Function,
    validator: Object,
    invoice_to_cancel: Object,
    settings: Object,
    sale_order: {
      default: false,
      type: Boolean,
    },
    receiver: {
      //Se usa para generar voucher a partir de remitos
      default: null,
      type: Object,
    },
    emitter: {
      //Se usa para generar voucher a partir de remitos
      default: null,
      type: Object,
    },
  },
  data: () => ({
    emitters: [],
    receivers: [],
    cbte_tipos: [],
    ptosvtas: [],
    conceptos: [],
    monedas: [],
  }),
  watch: {
    open: function (val) {
      if (val) {
        this.errors.clear();
        this.$validator.reset();
        this.loadComboData();
      }
    },
  },
  mounted() {
    this.loadComboData();
  },
  methods: {
    showOrdenCompra() {      
      return this.invoice.cbte_tipo  != null && [1,6,11,51,201,206,211].includes(this.invoice.cbte_tipo.Id);
    },
    requiredReceivers(){      
      if([1].includes(this.invoice.receiver.id)){//DIA
        return 'required';
      }
      return '';
    },
    receiver_name: (item) => (item == null ? "" : item.name + " (" + item.client.name + ")"),
    loadComboData() {
      this.loading_combos["emitters"] = true;
      emmiterService
        .filter({ enabled: true })
        .then((response) => {
          this.emitters = response.result;
          if (this.emitter != null && this.emitter.id > 0) {
            this.invoice.emitter = this.emitter;
            this.emitterChangeCombo();
          } else if (response.result.length == 1) {
            this.invoice.emitter = response.result[0];
            this.emitterChangeCombo();
          }
        })
        .finally(() => {
          this.loading_combos["emitters"] = false;
        });

      this.loading_combos["receivers"] = true;

      receiversService
        .filter({})
        .then((response) => {
          this.receivers = response.result;
          if (this.receiver != null && this.receiver.id > 0) {
            this.invoice.receiver = this.receiver;
          }
        })
        .finally(() => {
          this.loading_combos["receivers"] = false;
        });

      if (this.invoice.emitter != null && this.invoice.emitter.id > 0) this.emitterChangeCombo();
    },
    emitterChangeCombo(event) {
      this.dialog_loading = true;
      if (this.invoice.emitter != null && this.invoice.emitter.id) {
        this.getCbtesTipos();
        this.loading_combos["ptosvtas"] = true;
        emmiterService.ptosvtas(this.invoice.emitter.id).then((response) => {
          if (response.status) {
            this.ptosvtas = response.result;
            this.loading_combos["ptosvtas"] = false;
            this.invoice.pto_vta = response.result[0];
          }
        });

        this.loading_combos["conceptos"] = true;
        emmiterService.conceptos(this.invoice.emitter.id).then((response) => {
          if (response.status) {
            this.loading_combos["conceptos"] = false;
            this.conceptos = response.result;

            if (this.settings.business_type == ConstantSettings.business_type_service) {
              this.invoice.concepto = response.result[1]; //Servicios
            } else {
              this.invoice.concepto = response.result[0]; //Productos
            }
          }
        });

        this.loading_combos["monedas"] = true;
        emmiterService.monedas(this.invoice.emitter.id).then((response) => {
          if (response.status) {
            this.monedas = response.result;
            this.loading_combos["monedas"] = false;
            this.invoice.moneda = response.result[0];
            if (this.invoice_to_cancel != null) this.selectComboId(this.monedas, this.invoice_to_cancel.moneda, "Id");
          }
        });
      }
    },
    getCbtesTipos() {
      if (this.invoice.emitter != null && this.invoice.emitter.id > 0) {
        this.loading_combos["cbte_tipos"] = true;
        billingGeneralService.cbteTiposInvoice(this.invoice.emitter.id, "all").then((response) => {
          if (response.status) {
            this.cbte_tipos = response.result;
            this.loading_combos["cbte_tipos"] = false;
            if (this.invoice.id == 0) {
              this.invoice.cbte_tipo = response.result[0];
            }
            if (this.invoice_to_cancel != null) this.invoice.cbte_tipo = this.selectComboId(this.cbte_tipos, vouchersService.getCancelIdForCbteTipo(this.invoice_to_cancel.cbte_tipo.Id), "Id");

            if (this.sale_order) {
              //si se genera desde una orden de venta, eliminamos los comprobantes que son de tipo NC
              this.cbte_tipos = this.cbte_tipos.filter((cbte_tipo) => vouchersService.NoteCreditList().indexOf(cbte_tipo.Id) === -1);
            }
          }
        });
      }
    },
    receiverChangeCombo(event) {
      //this.getCbtesTipos();
      this.$emit("getLastvoucherOf");
    },
    selectComboId(combo, id, property_id = "id") {
      if (combo != null && combo.length > 0) {
        for (let i = 0; i < combo.length; i++) {
          if (combo[i][property_id] == id) {
            return combo[i];
          }
        }
      }
      return null;
    },
  },
};
</script>
