<template>
  <div style="padding: 20px; min-height: 630px; background-color: rgba(221, 225, 230, 1); border: solid 1px #ccc">
    <v-card v-if="list_access">
      <div class="row mb-2 mt-0">
        <div class="col-md-12">
          <div class="col-md-12" style="background:white; elevation-0">
            <div class="row pt-5">
              <div class="col-md-12 pt-0">
                <label class="caption">Agrupar por:</label>
                <v-radio-group class="mt-0" v-model="group_selected" row>
                  <v-radio label="Cliente" :value="group_client" color="primary"></v-radio>
                  <v-radio label="Tipo de cliente" :value="group_type_client" color="primary"></v-radio>
                  <v-radio label="Zona de cliente" :value="group_zone_client" color="primary"></v-radio>
                  <v-radio label="Vendedor" :value="group_salesman" color="primary"></v-radio>
                  <v-radio label="Producto" :value="group_product" color="primary"></v-radio>
                  <v-radio label="Rubro de producto" :value="group_category" color="primary"></v-radio>
                  <v-radio label="Tipo de producto" :value="group_type_product" color="primary"></v-radio>
                </v-radio-group>
              </div>

              <div class="col-md-5 pt-4">
                <div class="row">
                  <div class="col">
                    <v-combobox v-model="year_selected" :items="years" label="Año" dense></v-combobox>
                  </div>

                  <div class="col">
                    <v-combobox v-model="emitter_selected" autocomplete="off" item-text="name" item-value="id" :items="emitters" label="Emisor" class="pt-0 mt-0" clearable></v-combobox>
                  </div>
                </div>
              </div>

              <div class="col-md-5 pt-0">
                <label class="caption">Mostrar resultado en: </label>
                <v-radio-group class="mt-0" v-model="result_selected" row>
                  <v-radio label="Moneda" :value="result_currency" color="primary"></v-radio>
                  <v-radio v-if="isProductCompany()" label="Kilogramos" :value="result_kg" color="primary"></v-radio>
                  <v-radio label="Unidades" :value="result_unit" color="primary"></v-radio>
                </v-radio-group>
              </div>

              <div class="col-2" style="display: flex; justify-content: end">
                <v-btn color="info" class="" @click="filter">Buscar</v-btn>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12" style="background:rgb(237,237,237); elevation-0">
        <div class="row">
          <div class="col-12">
            <!-- Botón para abrir el modal de filtros -->
            <v-btn color="info" text @click="openFilterModal" small>+ Agregar filtros adicionales</v-btn>

            <!-- Chips que muestran los filtros seleccionados -->
            <v-chip v-for="(filter, index) in selected_filters" :key="index" class="ml-2" close @click:close="removeFilter(index)"> {{ filter.label }}: {{ filter.value }} </v-chip>
          </div>
        </div>
        <v-dialog v-model="dialog_filters" max-width="600px">
          <v-card>
            <v-card-title>
              <span class="headline">Filtros</span>
            </v-card-title>

            <v-card-text>
              <div class="row mt-4">
                <div class="col-md-12 pt-0">
                  <v-combobox
                    v-model="type_product_selected"
                    autocomplete="off"
                    data-vv-as="type_product_selected"
                    name="type_product_selected"
                    item-text="name"
                    item-value="id"
                    :items="product_types"
                    label="Tipo de producto"
                    clearable
                  ></v-combobox>
                </div>
                <div class="col-md-12 pt-0">
                  <v-combobox
                    v-model="salesman_selected"
                    autocomplete="off"
                    data-vv-as="salesman_selected"
                    name="salesman_selected"
                    item-text="name"
                    item-value="id"
                    :items="salesmans"
                    label="Vendedor"
                    clearable
                  ></v-combobox>
                </div>
                <div class="col-md-12 pt-0">
                  <v-combobox
                    v-model="client_selected"
                    autocomplete="off"
                    data-vv-as="client_selected"
                    name="client_selected"
                    item-text="name"
                    item-value="id"
                    :items="clients"
                    label="Cliente"
                    clearable
                  ></v-combobox>
                </div>
                <div class="col-md-12 pt-0">
                  <v-combobox
                    v-model="product_selected"
                    autocomplete="off"
                    data-vv-as="product_selected"
                    name="product_selected"
                    item-text="name"
                    item-value="id"
                    :items="products"
                    label="Producto"
                    clearable
                  ></v-combobox>
                </div>
                <div class="col-md-12 pt-0">
                  <v-combobox v-model="category_selected" autocomplete="off" item-text="name" item-value="id" :items="categories" label="Rubro de producto" clearable></v-combobox>
                </div>
                <div class="col-md-12 pt-0">
                  <v-combobox
                    v-model="client_zone_selected"
                    autocomplete="off"
                    data-vv-as="client_zone_selected"
                    name="client_zone_selected"
                    item-text="name"
                    item-value="id"
                    :items="zones_client"
                    label="Zona"
                    clearable
                  ></v-combobox>
                </div>
                <div class="col-md-12 pt-0">
                  <v-combobox v-model="months_selected" data-vv-as="months_selected" multiple :items="months" item-text="name" item-value="id" label="Meses del año" clearable chips> </v-combobox>
                </div>
              </div>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <!-- Botones para aplicar o cancelar filtros -->
              <v-btn color="error" text @click="closeFilterModal">Cancelar</v-btn>
              <v-btn color="success" text @click="filter">Aplicar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
    </v-card>
    <v-card v-if="list_access" class="mt-3">
      <v-card-title>
        <v-btn color="blue-grey" class="mt-3" small dark :loading="dialog_export" @click="exportFile()">
          Exportar
          <font-awesome-icon class="ml-2" icon="download"
        /></v-btn>

        <v-spacer></v-spacer>
        <v-text-field v-model="search" append-icon="search" label="Buscar" single-line hide-details></v-text-field>
      </v-card-title>

      <v-data-table
        :headers="headers"
        :items="list"
        :search="search"
        :loading="loading"
        loading-text="Cargando..."
        :options="{
          descending: true,
          itemsPerPage: 15,
        }"
        @pagination="updatePagination"
        :footer-props="{
          itemsPerPageOptions: [5, 10, 15, 50, -1],
        }"
      >
        <template v-slot:item="props">
          <tr>
            <td style="min-width: 250px">{{ props.item.name }}</td>
            <td>{{ props.item.year }}</td>

            <td v-if="headers.find((header) => header.value === 'january' && header.visible)" class="text-right">{{ formatedResult(props.item.january) }}</td>
            <td v-if="headers.find((header) => header.value === 'february' && header.visible)" class="text-right">{{ formatedResult(props.item.february) }}</td>
            <td v-if="headers.find((header) => header.value === 'march' && header.visible)" class="text-right">{{ formatedResult(props.item.march) }}</td>
            <td v-if="headers.find((header) => header.value === 'april' && header.visible)" class="text-right">{{ formatedResult(props.item.april) }}</td>
            <td v-if="headers.find((header) => header.value === 'may' && header.visible)" class="text-right">{{ formatedResult(props.item.may) }}</td>
            <td v-if="headers.find((header) => header.value === 'june' && header.visible)" class="text-right">{{ formatedResult(props.item.june) }}</td>
            <td v-if="headers.find((header) => header.value === 'july' && header.visible)" class="text-right">{{ formatedResult(props.item.july) }}</td>
            <td v-if="headers.find((header) => header.value === 'august' && header.visible)" class="text-right">{{ formatedResult(props.item.august) }}</td>
            <td v-if="headers.find((header) => header.value === 'september' && header.visible)" class="text-right">{{ formatedResult(props.item.september) }}</td>
            <td v-if="headers.find((header) => header.value === 'october' && header.visible)" class="text-right">{{ formatedResult(props.item.october) }}</td>
            <td v-if="headers.find((header) => header.value === 'november' && header.visible)" class="text-right">{{ formatedResult(props.item.november) }}</td>
            <td v-if="headers.find((header) => header.value === 'december' && header.visible)" class="text-right">{{ formatedResult(props.item.december) }}</td>
          </tr>
        </template>
        <template v-slot:no-results>
          <v-alert :value="true" color="error" icon="warning">
            {{ "Busqueda sin resultados - " + search }}
          </v-alert>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import Constant from "@/constants/sections";
import { generalService } from "@/libs/ws/generalService";
import { emmiterService } from "@/libs/ws/emmiterService";
import { salesmanService } from "@/libs/ws/salesmanService";
import { clientsService } from "@/libs/ws/clientsService";
import { formatUtils } from "@/libs/utils/formatUtils";
import GenericMixin from "@/mixins/GenericMixin.vue";
import RolMixin from "@/mixins/RolMixin.vue";
import ExportMixin from "@/mixins/ExportMixin.vue";
import SettingsMixin from "@/mixins/SettingsMixin.vue";

export default {
  components: {},
  mixins: [GenericMixin, RolMixin, ExportMixin, SettingsMixin],
  data() {
    return {
      Constant: Constant,
      formatUtils: formatUtils,
      service: generalService,
      page_title: "Ventas por producto",
      modal_title: "Ventas por producto",
      originalHeader: null,
      headers: [
        {
          text: "Nombre",
          align: "left",
          sortable: true,
          value: "name",
          visible: true,
        },
        {
          text: "Año",
          align: "left",
          sortable: true,
          value: "year",
          visible: true,
        },
        {
          text: "Enero",
          align: "right",
          sortable: true,
          value: "january",
          visible: false,
        },
        {
          text: "Febrero",
          align: "right",
          sortable: true,
          value: "february",
          visible: false,
        },
        {
          text: "Marzo",
          align: "right",
          sortable: true,
          value: "march",
          visible: false,
        },
        {
          text: "Abril",
          align: "right",
          sortable: true,
          value: "april",
          visible: false,
        },
        {
          text: "Mayo",
          align: "right",
          sortable: true,
          value: "may",
          visible: false,
        },
        {
          text: "Junio",
          align: "right",
          sortable: true,
          value: "june",
          visible: false,
        },
        {
          text: "Julio",
          align: "right",
          sortable: true,
          value: "july",
          visible: false,
        },
        {
          text: "Agosto",
          align: "right",
          sortable: true,
          value: "august",
          visible: false,
        },
        {
          text: "Septiembre",
          align: "right",
          sortable: true,
          value: "september",
          visible: false,
        },
        {
          text: "Octubre",
          align: "right",
          sortable: true,
          value: "october",
          visible: false,
        },
        {
          text: "Noviembre",
          align: "right",
          sortable: true,
          value: "november",
          visible: false,
        },
        {
          text: "Diciembre",
          align: "right",
          sortable: true,
          value: "december",
          visible: false,
        },
      ],
      years: [],
      list: [],
      group_client: 1,
      group_type_client: 2,
      group_product: 3,
      group_category: 4,
      group_type_product: 5,
      group_salesman: 6,
      group_zone_client: 7,
      group_selected: 1,
      result_selected: 1,
      result_currency: 1,
      result_kg: 2,
      result_unit: 3,
      default_result_selected: null,
      year_selected: new Date().getFullYear(),
      emitter_selected: null,
      emitters: [],
      selected_filters: [],
      dialog_filters: false,
      type_product_selected: null,
      salesman_selected: null,
      client_selected: null,
      product_selected: null,
      client_zone_selected: null,
      category_selected: null,
      months_selected: [
        { id: "january", name: "Enero" },
        { id: "february", name: "Febrero" },
        { id: "march", name: "Marzo" },
        { id: "april", name: "Abril" },
        { id: "may", name: "Mayo" },
        { id: "june", name: "Junio" },
        { id: "july", name: "Julio" },
        { id: "august", name: "Agosto" },
        { id: "september", name: "Septiembre" },
        { id: "october", name: "Octubre" },
        { id: "november", name: "Noviembre" },
        { id: "december", name: "Diciembre" },
      ],
      product_types: [],
      zones_client: [],
      salesmans: [],
      clients: [],
      products: [],
      categories: [],
      months: [
        { id: "january", name: "Enero" },
        { id: "february", name: "Febrero" },
        { id: "march", name: "Marzo" },
        { id: "april", name: "Abril" },
        { id: "may", name: "Mayo" },
        { id: "june", name: "Junio" },
        { id: "july", name: "Julio" },
        { id: "august", name: "Agosto" },
        { id: "september", name: "Septiembre" },
        { id: "october", name: "Octubre" },
        { id: "november", name: "Noviembre" },
        { id: "december", name: "Diciembre" },
      ],
    };
  },
  mounted() {
    this.originalHeader = JSON.parse(JSON.stringify(this.headers));

    this.loadList(this.service);
    this.loadEmitters();
    this.getYears();
    this.loadProductType();
    this.loadZonesClient();
    this.loadVendedores();
    this.loadClients();
    this.loadProducts();
    this.loadCategories();
  },
  methods: {
    hasOwnProperty(obj, key) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        return true;
      }
    },
    updateHeaders(monthValues) {
      this.headers = this.originalHeader.map((header) => ({
        ...header,
        visible:
          ["name", "year"].includes(header.value) || // Siempre visibles
          monthValues.includes(header.value), // Meses seleccionados
      }));

      this.headers = this.headers.filter((header) => header.visible);
    },

    loadCategories() {
      generalService.filter({}, Constant.SECTION_CATEGORIES).then((response) => {
        this.categories = response.result;
      });
    },
    loadProducts() {
      generalService.filter({}, Constant.SECTION_PRODUCTS).then((response) => {
        this.products = response.result;
      });
    },
    loadClients() {
      clientsService
        .filter({ enabled: true })
        .then((response) => {
          this.clients = response.result;
        })
        .catch((e) => {
          console.log("clients.filter error");
        });
    },
    loadVendedores() {
      salesmanService
        .filter({ enabled: true })
        .then((response) => {
          this.salesmans = response.result;
        })
        .catch((e) => {
          console.log("salesmans.filter error");
        });
    },
    loadZonesClient() {
      generalService.setSection(Constant.SECTION_CLIENT_ZONE);
      generalService
        .filter()
        .then((response) => {
          this.zones_client = response.result;
        })
        .catch((e) => {
          console.log("zones_client.filter error");
          console.log(e);
        });
    },
    loadProductType() {
      generalService.filter({}, Constant.SECTION_PRODUCT_TYPE).then((response) => {
        this.product_types = response.result;
      });
    },
    openFilterModal() {
      this.dialog_filters = true;
    },
    removeFilter(index) {
      const filtered = this.selected_filters[index];
      this.selected_filters.splice(index, 1);

      if (filtered.label === "Tipo de producto") {
        this.type_product_selected = null;
      } else if (filtered.label === "Vendedor") {
        this.salesman_selected = null;
      } else if (filtered.label === "Cliente") {
        this.client_selected = null;
      } else if (filtered.label === "Zona cliente") {
        this.client_zone_selected = null;
      } else if (filtered.label === "Producto") {
        this.product_selected = null;
      } else if (filtered.label === "Rubro de producto") {
        this.category_selected = null;
      }

      this.filter();
    },
    closeFilterModal() {
      this.dialog_filters = false;
    },
    loadEmitters() {
      emmiterService.list_for_stats({ enabled: true }).then((response) => {
        this.emitters = response.result;
      });
    },
    formatedResult(item) {
      if (this.default_result_selected == this.result_currency) {
        return formatUtils.formatPrice(item);
      } else {
        return formatUtils.formatNumber(item);
      }
    },
    getYears() {
      var i = 2020;
      var year_current = new Date().getFullYear();
      for (i; i <= year_current; i++) {
        this.years.push(i);
      }
    },

    getFilterParams() {
      const param = {
        year: this.year_selected,
        result_in: this.result_selected,
        group: this.group_selected,
        emitter: this.emitter_selected != null ? this.emitter_selected.id : null,
        type_product: this.type_product_selected != null ? this.type_product_selected.id : null,
        salesman: this.salesman_selected != null ? this.salesman_selected.id : null,
        zone_client: this.client_zone_selected != null ? this.client_zone_selected.id : null,
        client: this.client_selected != null ? this.client_selected.id : null,
        product: this.product_selected != null ? this.product_selected.id : null,
        category: this.category_selected != null ? this.category_selected.id : null,
        months: this.months_selected.map((month) => month.id.toLowerCase()) || [],
      };

      this.selected_filters = [
        { label: "Tipo de producto", value: this.type_product_selected != null ? this.type_product_selected.name : null },
        { label: "Vendedor", value: this.salesman_selected != null ? this.salesman_selected.name : null },
        { label: "Cliente", value: this.client_selected != null ? this.client_selected.name : null },
        { label: "Zona cliente", value: this.client_zone_selected != null ? this.client_zone_selected.name : null },
        { label: "Producto", value: this.product_selected != null ? this.product_selected.name : null },
        { label: "Rubro de producto", value: this.category_selected != null ? this.category_selected.name : null },
      ].filter((filter) => filter.value);

      this.dialog_filters = false;

      this.updateHeaders(param.months);

      return param;
    },

    filter() {
      this.loadList(this.service, this.getFilterParams());
    },
    loadList(service, param = null) {
      this.loading = true;

      if (param == null) {
        param = this.getFilterParams();
      }

      this.open_filter = false;
      service
        .query(param, Constant.SECTION_REPORTS + "/ventas-por-anio")
        .then((response) => {
          this.default_result_selected = this.result_selected;
          this.list = response.result;
          this.loading = false;

          if (typeof this.selected !== "undefined") {
            this.selected = [];
          }
        })
        .catch((e) => {
          console.log("service.filter error");
          console.log(e);
          this.loading = false;
        });
    },
    exportFile() {
      var param = this.getFilterParams();
      param["export"] = true;
      let filename = "Reporte-Ventas-" + this.year_selected + "-";
      switch (this.default_result_selected) {
        case this.result_currency:
          filename += "currency";
          break;
        case this.result_kg:
          filename += "kg";
          break;
        case this.result_unit:
          filename += "unit";
          break;
      }

      this.exportExcel(Constant.SECTION_REPORTS + "/ventas-por-anio", param, filename + ".xlsx");
    },
  },
};
</script>
